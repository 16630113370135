/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import {
  AccountBalance,
  ScreenShare,
  ScreenLockLandscape,
  PeopleSharp,
  Build,
  AttachMoney,
  Money,
  People,
  Power,
  PersonPinCircle,
  MonetizationOn,
  FeaturedPlayList,
  PinDrop,
  CardMembershipSharp,
  MonetizationOnOutlined,
  Search,
  Storefront,
  DevicesOther,
  LocationCity,
  TouchApp,
  MoneyOutlined,
  ViewColumnOutlined,
  FormatListNumberedOutlined,
  Loyalty,
  Redeem,
  Category,
  Payment,
  CardGiftcard,
  Link as LinkIcon,
  RssFeed,
  StorefrontOutlined,
  ImportantDevicesOutlined,
  DateRange,
} from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  DASHBOARD,
  TAXES,
  COMMISSIONS,
  FEES,
  AMOUNTS,
  REFERRALS,
  ALLIES,
  REMITTANCES,
  PERSON_FEATURES_FLAG,
  DISTRIBUTION_CENTER,
  CARDS,
  LIMIT_REPOS,
  CARDS_QUERY,
  BANNERS,
  POS_DEVICE,
  DISTRIBUTION_POS,
  APPVERSION,
  APPCOMMERCEVERSION,
  PROMOTIONS,
  SEGMENTATION,
  TYPE_PROMOTIONS,
  PROVIDERS_SERVICE_PAY,
  PROMO_CATEGORIES,
  PROVIDERS,
  CATEGORIES_SERVICE_PAYMENT,
  CATEGORIES_SERVICE_RECHARGE,
  PROMOTIONS_APP_SCREEN,
  DISTRIBUTION_ZONE,
  FEATURED,
  ROLSCREENS,
  ROLREGISTER,
  ROLASSIGNMENT,
  PAYMENTS_LINK,
  TAP_TO_PHONE,
  NATIONAL_HOLIDAYS,
  COMMERCE_FEATURES_FLAG,
  UMBRAL_IFO1_FEATURES_FLAG,
  EXPIRATION_DATE_REMITTANCE,
  COMMERCE_WEB_FEATURES_FLAG,
  REMITTANCES_NO_USER,
  CARDS_COST_LIMIT,
  COMMERCIAL_CATEGORY,
} from '../../navigation/screenNames';

export const SIDEBAR_MENU_OPTIONS = [
  {
    title: 'Inicio',
    navigation: `/${DASHBOARD}`,
    icon: <DashboardIcon />,
  },
  {
    title: 'Administrador de roles',
    icon: <Build />,
    colapse: 'administrator',
    sections: [
      {
        navigation: `/${ROLSCREENS}`,
        title: 'Registro de secciones',
        icon: <ScreenShare />,
      },
      {
        navigation: `/${ROLREGISTER}`,
        title: 'Registro de roles',
        icon: <ScreenLockLandscape />,
      },
      {
        navigation: `/${ROLASSIGNMENT}`,
        title: 'Asignación de roles',
        icon: <PeopleSharp />,
      },
    ],
  },
  {
    title: 'Mantenimiento',
    icon: <Build />,
    colapse: 'mantainance',
    sections: [
      {
        navigation: `/${TAXES}`,
        title: 'Impuestos',
        icon: <AccountBalance />,
      },
      {
        navigation: `/${COMMISSIONS}`,
        title: 'Comisiones',
        icon: <AttachMoney />,
      },
      {
        navigation: `/${COMMERCIAL_CATEGORY}`,
        title: 'Categoría de comercios',
        icon: <Storefront />,
      },
      {
        navigation: `/${FEES}`,
        title: 'Comisiones App',
        icon: <AttachMoney />,
      },
      {
        navigation: `/${AMOUNTS}`,
        title: 'Limites',
        icon: <Money />,
      },
    ],
  },
  {
    title: 'Referidos',
    icon: <People />,
    colapse: 'referrals',
    sections: [
      {
        navigation: `/${REFERRALS}`,
        title: 'Maestro',
        icon: <PersonPinCircle />,
      },
      {
        navigation: `/${ALLIES}`,
        title: 'Super Aliados',
        icon: <Power />,
      },
    ],
  },
  {
    title: 'Segmentación',
    navigation: `/${SEGMENTATION}`,
    icon: <ViewColumnOutlined />,
  },
  {
    title: 'Remesas',
    icon: <Build />,
    colapse: 'remittance',
    sections: [
      {
        navigation: `/${REMITTANCES}`,
        title: 'Tasa USD',
        icon: <MonetizationOn />,
      },
      {
        navigation: `/${EXPIRATION_DATE_REMITTANCE}`,
        title: 'Vencimiento No User',
        icon: <DateRange />,
      },
      {
        navigation: `/${REMITTANCES_NO_USER}`,
        title: 'Remesas No User',
        icon: <MonetizationOnOutlined />,
      },
    ],
  },
  {
    title: 'Feature flag',
    icon: <FeaturedPlayList />,
    colapse: 'feature_flag',
    sections: [
      {
        navigation: `/${PERSON_FEATURES_FLAG}`,
        title: 'App Personas',
        icon: <People />,
      },
      {
        navigation: `/${COMMERCE_FEATURES_FLAG}`,
        title: 'App Comercios',
        icon: <Storefront />,
      },
      {
        navigation: `/${UMBRAL_IFO1_FEATURES_FLAG}`,
        title: 'IF01',
        icon: <Storefront />,
      },
      {
        navigation: `/${COMMERCE_WEB_FEATURES_FLAG}`,
        title: 'Web Comercios',
        icon: <Storefront />,
      },
    ],
  },
  {
    title: 'Solicitud de tarjetas',
    icon: <CardMembershipSharp />,
    colapse: 'request',
    sections: [
      {
        navigation: `/${DISTRIBUTION_ZONE}`,
        title: 'Zonas de distribución',
        icon: <LocationCity />,
      },
      {
        navigation: `/${DISTRIBUTION_CENTER}`,
        title: 'Centros de distribución',
        icon: <PinDrop />,
      },
    ],
  },
  {
    title: 'Tarjetas',
    icon: <CardMembershipSharp />,
    colapse: 'cards',
    sections: [
      {
        navigation: `/${CARDS}`,
        title: 'Costo por solicitud',
        icon: <MonetizationOnOutlined />,
      },
      {
        navigation: `/${LIMIT_REPOS}`,
        title: 'límite de reposiciones',
        icon: <Money />,
      },
      {
        navigation: `/${CARDS_QUERY}`,
        title: 'Consulta',
        icon: <Search />,
      },
    ],
  },
  {
    title: 'Tarjetas de crédito',
    icon: <CardMembershipSharp />,
    colapse: 'credit_card',
    sections: [
      {
        navigation: `/${CARDS_COST_LIMIT}`,
        title: 'Límites y costos',
        icon: <MonetizationOnOutlined />,
      },
    ],
  },
  {
    title: 'Comercios',
    icon: <Storefront />,
    colapse: 'payments_link',
    sections: [
      {
        navigation: `/${PAYMENTS_LINK}`,
        title: 'Link de pagos',
        icon: <LinkIcon />,
      },
      {
        navigation: `/${TAP_TO_PHONE}`,
        title: 'Tap to phone',
        icon: <RssFeed />,
      },
    ],
  },
  {
    title: 'Mercadeo',
    icon: <Storefront />,
    colapse: 'mercadeo',
    sections: [
      {
        navigation: `/${BANNERS}`,
        title: 'Banners Promocionales',
        icon: <MonetizationOnOutlined />,
      },
      {
        navigation: `/${FEATURED}`,
        title: 'Promociones Destacadas',
        icon: <ImportantDevicesOutlined />,
      },
      {
        navigation: `/${PROMO_CATEGORIES}`,
        title: 'Categorias Promociones',
        icon: <Loyalty />,
      },
      {
        navigation: `/${TYPE_PROMOTIONS}`,
        title: 'Tipo de promociones',
        icon: <FormatListNumberedOutlined />,
      },
      {
        navigation: `/${PROMOTIONS_APP_SCREEN}`,
        title: 'Promociones Billet',
        icon: <Redeem />,
      },
      {
        navigation: `/${PROMOTIONS}`,
        title: 'Promociones Cashin',
        icon: <MoneyOutlined />,
      },
    ],
  },
  {
    title: 'POS',
    icon: <Build />,
    colapse: 'pos',
    sections: [
      {
        navigation: `/${POS_DEVICE}`,
        title: 'Dispositivo',
        icon: <DevicesOther />,
      },
      {
        navigation: `/${DISTRIBUTION_POS}`,
        title: 'Distribución',
        icon: <LocationCity />,
      },
    ],
  },
  {
    title: 'Pago de servicios',
    icon: <Payment />,
    colapse: 'payment',
    sections: [
      {
        navigation: `/${PROVIDERS_SERVICE_PAY}`,
        title: 'Proveedores',
        icon: <StorefrontOutlined />,
      },
      {
        navigation: `/${CATEGORIES_SERVICE_PAYMENT}`,
        title: 'Categorías',
        icon: <Category />,
      },
    ],
  },
  {
    title: 'Recarga de servicios',
    icon: <CardGiftcard />,
    colapse: 'recharge',
    sections: [
      {
        navigation: `/${PROVIDERS}`,
        title: 'Proveedores',
        icon: <StorefrontOutlined />,
      },
      {
        navigation: `/${CATEGORIES_SERVICE_RECHARGE}`,
        title: 'Categorías',
        icon: <Category />,
      },
    ],
  },
  {
    title: 'Días feriados',
    navigation: `/${NATIONAL_HOLIDAYS}`,
    icon: <DateRange />,
  },
  {
    title: 'Versionamiento App',
    icon: <TouchApp />,
    colapse: 'versions',
    sections: [
      {
        navigation: `/${APPVERSION}`,
        title: 'App Persona',
        icon: <People />,
      },
      {
        navigation: `/${APPCOMMERCEVERSION}`,
        title: 'App Comercios',
        icon: <Storefront />,
      },
    ],
  },
];
