/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, Toaster, CustomSwitch, CustomSelect, Loading,
} from '../../../../components';
import AlertConfirm from '../../../../components/Crosscutting/AlertConfirm';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import {
  updateCommercialCategory,
  getCommercialCategoryService,
  postComercialCategoryService,
} from '../../../../services';
import { errorAlert } from '../../constants';
import { RISK_CLASIFICATION } from './constants';
import { cleanString } from '../../../../utils';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  getCommercialCategories,
  defaultValues,
  sectorsList,
  updateMode = false,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [active, setActive] = useState(true);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [existentMcc, setExistentMcc] = React.useState(null);
  const providerRef = useRef();

  const {
    handleSubmit, control, errors, reset, getValues,
  } = useForm({ defaultValues });

  const createCommercialCategory = async (payload) => {
    setLoading(true);
    try {
      await postComercialCategoryService(payload);
      setLoading(false);
      return true;
    } catch (err) {
      setLoading(false);
      setAlert(errorAlert);
    }
  };

  const updateCommercialCategoryValues = async (categoryOldValues = {}) => {
    const {
      classification, description, mcc, rubro,
    } = getValues();
    const selectedRubro = sectorsList[rubro];

    const payload = {
      ...categoryOldValues,
      detail_name_es: description,
      mcc: Number(mcc),
      itbis: active,
      rubro: selectedRubro.description,
      rubro_id: selectedRubro.id,
      classification,
    };
    setOpenAlertConfirm(false);
    setLoading(true);
    try {
      await updateCommercialCategory(payload);
      await getCommercialCategories();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return setAlert(errorAlert);
    }

    onClose();
    providerRef.current.enqueueSnackbar('Se ha actualizado la categoría comercial exitosamente', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'botom',
        horizontal: 'center',
      },
    });
  };

  const handleOnclose = () => {
    onClose();
  };

  const updateCategory = async () => {
    setLoading(true);
    const { mcc } = getValues();
    try {
      const {
        data: category,
      } = await getCommercialCategoryService(mcc);
      await updateCommercialCategoryValues(category);
    } catch (e) {
      setAlert(errorAlert);
    }
    setLoading(false);
  };

  const handleSave = async (dataParam) => {
    let message;
    let isSuccess = false;
    let responseStatus;
    let commercialCategory;
    setLoading(true);
    try {
      const {
        data: category,
        status,
      } = await getCommercialCategoryService(dataParam.mcc);
      commercialCategory = category;
      responseStatus = status;
    } catch (e) {
      setLoading(false);
      setAlert(errorAlert);
    }

    const shouldUpdate = !!(responseStatus === 200 && commercialCategory);
    if (shouldUpdate) {
      setExistentMcc(commercialCategory);
      setOpenAlertConfirm(true);
    }

    if (responseStatus === 404) {
      const selectedRubro = sectorsList[dataParam.rubro];
      const body = {
        detail_name_es: cleanString(dataParam.description),
        mcc: Number(dataParam.mcc),
        itbis: active,
        justBackOffice: false,
        rubro: selectedRubro.description,
        rubro_id: selectedRubro.id,
        classification: dataParam.classification,
        enabled: true,
      };
      isSuccess = await createCommercialCategory(body);
      message = 'Se ha creado la categoría comercial exitosamente';
    }

    if (isSuccess) {
      await getCommercialCategories();
      providerRef.current.enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      });
      onClose();
    }
  };

  const saveCategory = () => handleSubmit(handleSave);

  const getRubroOptions = (rubrosList) => rubrosList.map((rubro) => ({ value: rubro, label: rubro }));

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.active);
    }
  }, [data]);

  const getModalMessage = () => {
    const values = getValues();
    const selectedRubro = sectorsList[values.rubro];
    const text = `La categoria comercial que intentas agregar ya existe.
            Deseas actualizar los siguientes cambios?:
            ${values?.mcc} - ${values?.description} (${selectedRubro.description})`;
    return text;
  };

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={updateMode ? updateCategory : saveCategory()}
        enabledControls
      >

        <>
          {openAlertConfirm && (
          <AlertConfirm
            isOpen={openAlertConfirm}
            tituloModal="¡Categoría existente!"
            textButtonAcept="Actualizar"
            colorButtonAcept="secondary"
            mensajeModal={getModalMessage()}
            handleAccept={() => { updateCommercialCategoryValues(existentMcc); }}
            onCancel={() => setOpenAlertConfirm(false)}
            onClose={() => setOpenAlertConfirm(false)}
          />
          )}
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="MCC *"
                  name="mcc"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                  type="number"
                  disabled={updateMode}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Descripción *"
                  name="description"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Elegir Categoría/Rubro *"
                  name="rubro"
                  control={control}
                  error={errors}
                  options={getRubroOptions(Object.keys(sectorsList))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Clasificación de Riesgo *"
                  name="classification"
                  control={control}
                  error={errors}
                  options={RISK_CLASIFICATION}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                    <FormControlLabel
                      control={(
                        <CustomSwitch
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                          name="itbis"
                          color="black"
                        />
                    )}
                      label="Aplica Itbis"
                      labelPlacement="start"
                    />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} />

            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  sectorsList: PropTypes.shape({ [PropTypes.string]: PropTypes.object }).isRequired,
};

export default ActionForm;
